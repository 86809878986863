import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useImpression } from "../analytics/utils"

const NotFound = ({ location }) => {
  useImpression("NotFound")
  return (
    <Layout location={location}>
      <Seo title="404 Not found" />
      <br />
      <br />
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <h2 style={{ fontSize: 20 }}>404 Error</h2>
        <h1>
          Oh no! We can’t find the page
          <br /> you’re looking for.
        </h1>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Layout>
  )
}

export default NotFound
